<template>
  <div class="home">
    <div class="logo-title">
      校园服饰下单平台
    </div>
    <div class="school" v-for="(item,index) in schoolList" :key="index" @click="handlerClick(item)">
      <img src="../assets/081-school-1.png" alt="" style="width:30%;">
      <span class="title">{{item.title}}</span>
    </div>
    <div class="school last">
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  methods:{
    handlerClick(item){
        this.$router.push({name:'login',query:{id:item.id}})
    },
  },
  data(){
    return {
      schoolList:[
        {
          title:'浙师大',
          url:'https://www.tianjipay.com/mobile/DiyPage/index?mId=59315&sId=82686',
          id:0
        },
        {
          title:'泗州小学',
          url:'https://www.tianjipay.com/mobile/DiyPage/index?mId=59315&sId=276497',
          id:1
        },
        {
          title:'浙师大幼儿园',
          url:'https://www.tianjipay.com/mobile/DiyPage/index?mId=59315&sId=276494',
          id:2
        },
        {
          title:'玉兰幼儿园',
          url:'https://www.tianjipay.com/mobile/DiyPage/index?mId=59315&sId=276490',
          id:3
        },
        {
          title:'车站路小学',
          url:'https://www.tianjipay.com/mobile/DiyPage/index?mId=59315&sId=276487',
          id:4
        },
        {
          title:'吴镇小学',
          url:'https://www.tianjipay.com/mobile/DiyPage/index?mId=59315&sId=276483',
          id:5
        },
        {
          title:'慈山三小',
          url:'https://www.tianjipay.com/mobile/DiyPage/index?mId=59315&sId=276469',
          id:6
        },
        {
          title:'慈山二小',
          url:'https://www.tianjipay.com/mobile/DiyPage/index?mId=59315&sId=276467',
          id:7
        },
        {
          title:'慈山一小',
          url:'https://www.tianjipay.com/mobile/DiyPage/index?mId=59315&sId=276465',
          id:8
        },
        {
          title:'杜鹃小学',
          url:'https://www.tianjipay.com/mobile/DiyPage/index?mId=59315&sId=276461',
          id:9
        },{
          title:'实验二小',
          url:'https://www.tianjipay.com/mobile/DiyPage/index?mId=59315&sId=276457',
          id:10
        },
        {
          title:'实验幼儿园',
          url:'https://www.tianjipay.com/mobile/DiyPage/index?mId=59315&sId=307149',
          id:11
        },
         {
          title:'实验小学北校区',
          url:'https://www.tianjipay.com/pageapi/Index/index?mId=59315&sId=580761&router=/pages/home/home?pageId=65963',
          id:12
        },
         {
          title:'实验小学南校区',
          url:'https://www.tianjipay.com/pageapi/Index/index?mId=59315&sId=580737&router=/pages/home/home?pageId=65960',
          id:13
        },{
          title:'华师大二附嘉善学校',
          url:'https://www.51hdb.com/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=22',
          id:14
        },{
          title:'惠民小学',
          url:'https://www.51hdb.com/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=26',
          id:15
        },{
          title:'天凝幼儿园',
          url:'https://www.51hdb.com/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=42',
          id:16
        },{
          title:'亭桥小学',
          url:'https://www.51hdb.com/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=41',
          id:17
        },{
          title:'慈山四小',
          url:'https://www.51hdb.com/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=40',
          id:18
        },{
          title:'杜鹃幼儿园',
          url:'https://www.51hdb.com/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=39',
          id:19
        },{
          title:'城西小学',
          url:'https://www.51hdb.com/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=39',
          id:20
        },{
          title:'洪溪小学',
          url:'https://www.51hdb.com/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=39',
          id:21
        },{
          title:'大通小学',
          url:'https://www.51hdb.com/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=39',
          id:22
        },{
          title:'俞汇小学',
          url:'https://www.51hdb.com/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=39',
          id:23
        },{
          title:'逸夫小学',
          url:'https://www.51hdb.com/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=39',
          id:24
        },{
          title:'泗洲小学',
          url:'https://www.51hdb.com/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=39',
          id:25
        },{
          title:'陶庄小学',
          url:'https://www.51hdb.com/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=39',
          id:26
        },{
          title:'大云学校',
          url:'https://www.51hdb.com/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=39',
          id:27
        },{
          title:'泗洲中学',
          url:'https://www.51hdb.com/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=39',
          id:28
        },{
          title:'硕士小学',
          url:'https://www.51hdb.com/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=39',
          id:29
        },{
          title:'实验中学',
          url:'https://www.51hdb.com/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=39',
          id:30
        },{
          title:'嘉善四中',
          url:'https://www.51hdb.com/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=39',
          id:31
        },{
          title:'上海大学附属嘉善实验学校',
          url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=148',
          id:32
        },{
          title:'上海理工大学附属嘉善实验学校',
          url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=149',
          id:33
        },{
          title:'里泽中心学校',
          url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=339',
          id:34
        },{
          title:'上海世外教育附属西塘小学',
          url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=338',
          id:35
        },



        

      ]
    }
  }
}
</script>
<style scoped lang="less">
  .logo-title{
    width:96%;
    margin: 10px auto;
    height:60px;
    text-align: center;
    line-height: 60px;
    color: white;
  }
  .home{
    width:100%;
    height:100vh;
    display: flex;
    overflow-y:auto;
    flex-wrap: wrap;
    justify-content: space-around;
    .school{
      background: white;
      border-radius: 4px;
      width:30%;
      box-shadow:  0 0 5px #cccccc;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      padding: 10px 0;
      .title{
        display: inline-block;
        margin-top: 10px;
        font-size: 10px;
        width:100%;
        text-align: center;
      }
    }
    .last{
      box-shadow:  none;
      background-color: transparent;
    }
  }
</style>
